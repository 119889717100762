import { QueryClient } from '@tanstack/react-query';

export function generateQueryClient(): QueryClient {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60, // 1 minutes
        cacheTime: 1000 * 60, // 1 minutes
      },
    },
  });
}
export const queryClient = generateQueryClient();
