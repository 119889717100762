import { AuthProviderProps } from 'oidc-react';
import process from 'process';

export const oidcConfig: AuthProviderProps = {
  autoSignIn: false,
  loadUserInfo: false,
  authority: process?.env?.REACT_APP_AUTHORITY,
  clientId: process?.env?.REACT_APP_CLIENT_ID,
  redirectUri: process?.env?.REACT_APP_REDIRECT_URL,
  clientSecret: process?.env?.REACT_APP_CLIENT_SECRET,
  scope: process?.env?.REACT_APP_SCOPE,
};
