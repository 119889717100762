import React, { CSSProperties } from "react";
import { ButtonProps } from "../Button";

export interface DividerProps extends ButtonProps {
  className?: string;

  styles?: CSSProperties;

  // Only for testing purposes
  testid?:string
}

export const Divider = ({ className = "", styles, testid }: DividerProps) => {
  return <div data-testid = {testid} className={`divider ${className}`} style={styles} />;
};
