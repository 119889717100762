import { NavFooter } from '@components/navigation/NavFooter';
import { NavHeader } from '@components/navigation/NavHeader';

export function PageContainer({ children }: { children: JSX.Element }) {
  return (
    <main className="page-container">
      <NavHeader />
      <div className="page-container-content">{children}</div>
      <NavFooter />
    </main>
  );
}
