import whitesherpasslogo from '@assets/white-sherpass-logo.svg';
import { Divider } from '@components/common/divider/Divider';
import { Text } from '@components/common/Text';

export function NavFooter() {
  return (
    <footer className="page-footer">
      <div className="footer-col">
        <div className="footer-left">
          <img
            src={whitesherpasslogo}
            className="footer-logo"
            alt="Sherpass Logo"
          />
        </div>
        <div className="footer-right">
          <div className="footer-right-content">
            <Text.Paragraph2 className="mb-8 bold">
              Get in touch
            </Text.Paragraph2>
            <Text.Paragraph1>
              <>
                Heizel Business Park – building II
                <br />
                Romeinsesteenweg 564
                <br />
                41853 Strombeek-Bever
                <br />
                Belgium
              </>
            </Text.Paragraph1>

            <Text.Paragraph1 className="mt-8">
              <>
                Email:{' '}
                <a href="mailto:support@sherpass.app">support@sherpass.app</a>
              </>
            </Text.Paragraph1>
          </div>
        </div>
      </div>
      <Divider className="footer-center mt-24 mb-24" />
      <div className="footer-col">
        <div className="footer-left"></div>
        <div className="footer-right">
          <div className="footer-right">
            <div className="footer-copyright-section">
              <Text.Paragraph1 className="mr-24">
                &copy; Sherpass 2022
              </Text.Paragraph1>
              <Text.Paragraph1>
                <a
                  href="https://www.sherpass.app/privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy &amp; Cookies
                </a>
              </Text.Paragraph1>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
