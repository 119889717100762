import { useAuth } from 'oidc-react';
import { Navigate, Outlet } from 'react-router';

export function PrivateRoutes() {
  const { userData } = useAuth();
  return userData?.access_token !== undefined ? (
    <Outlet />
  ) : (
    <Navigate to="/signIn" />
  );
}
