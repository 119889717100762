import sherpassLogo from '@assets/sherpass-logo.svg';
import { Button } from '@components/common/Button';
import { useAuth } from 'oidc-react';
import { useNavigate } from 'react-router';

export function NavHeader() {
  const { signOut } = useAuth();
  const navigate = useNavigate();
  const onSignOut = async () => {
    await signOut();
    navigate('/signIn');
  };
  return (
    <header className="navheader-container">
      <>
        <div className="navheader-section navheader-section-left">
          <img
            src={sherpassLogo}
            alt="Sherpass Logo in color"
            className="header-logo"
          />
        </div>
        <div className="navheader-section navheader-section-right">
          <Button asAction type="flat" color="info" onClick={onSignOut}>
            Log out
          </Button>
        </div>
      </>
    </header>
  );
}
