/* eslint-disable @typescript-eslint/no-unused-expressions */
import './App.css';

import axios from 'axios';
import { AuthProvider } from 'oidc-react';
import process from 'process';
import * as React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';

import { oidcConfig } from './oidcConfig';
import { queryClient } from './reactQuery/queryClient';
import Routes from './Routes';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

function App() {
  React.useEffect(() => {
    axios.defaults.baseURL = process?.env?.REACT_APP_APIROOT;
  }, []);

  return (
    <>
      <AuthProvider {...oidcConfig}>
        <QueryClientProvider client={queryClient}>
          <Routes />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </AuthProvider>
    </>
  );
}

export default App;
