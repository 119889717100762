import React from 'react';

export interface TextProps {
  children: string | JSX.Element;
  style?: React.CSSProperties;
  className?: string | undefined;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export function Text({ className, children, onClick, ...props }: TextProps) {
  return (
    <p
      {...props}
      onClick={onClick}
      className={`customText ${onClick ? 'cursor-pointer' : ''} ${className}`}
    >
      {children}
    </p>
  );
}

Text.H1 = function ({ className, ...props }: TextProps) {
  return <Text {...props} className={`${className} h1`} />;
};
Text.H2 = function ({ className, ...props }: TextProps) {
  return <Text {...props} className={`${className} h2`} />;
};
Text.H3 = function ({ className, ...props }: TextProps) {
  return <Text {...props} className={`${className} h3`} />;
};
Text.H4 = function ({ className, ...props }: TextProps) {
  return <Text {...props} className={`${className} h4`} />;
};
Text.Paragraph1 = function ({ className, ...props }: TextProps) {
  return <Text {...props} className={`${className} p1`} />;
};
Text.Paragraph2 = function ({ className, ...props }: TextProps) {
  return <Text {...props} className={`${className} p2`} />;
};
Text.Paragraph3 = function ({ className, ...props }: TextProps) {
  return <Text {...props} className={`${className} p3`} />;
};
Text.Navigation = function ({ className, ...props }: TextProps) {
  return <Text {...props} className={`${className} navigation`} />;
};
