import { PageContainer } from '@components/navigation/PageContainer';
import { PrivateRoutes } from '@components/navigation/PrivateRoutes';
import axios from 'axios';
import { useAuth } from 'oidc-react';
import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const NotFound = React.lazy(() => import('./pages/NotFound'));
const Home = React.lazy(() => import('./pages/Home'));
const SignIn = React.lazy(() => import('./pages/SignIn'));
const Callback = React.lazy(() => import('./pages/Callback'));

export default function RoutesComponent() {
  const { userData } = useAuth();

  // Event handler when the userdata is updated (ie Access Token)
  React.useEffect(() => {
    if (userData?.access_token !== undefined) {
      // Add interceptor with the access token
      axios.interceptors.request.use(async (req: any) => {
        const accessToken = userData.access_token;
        if (req && req.headers) {
          req.headers.authorization = `Bearer ${accessToken}`;
        }
        return req;
      });
    } else {
      axios.interceptors.request.clear();
    }
  }, [userData]);

  return (
    <React.Suspense fallback={<>...</>}>
      <React.StrictMode>
        <BrowserRouter>
          <Routes>
            {/* Protected routes which require authentication */}
            <Route element={<PrivateRoutes />}>
              <Route
                path="/"
                element={
                  <PageContainer>
                    <Home />
                  </PageContainer>
                }
              />
            </Route>
            {/* Public routes */}
            <Route path="/callback" element={<Callback />} />
            <Route path="/signIn" element={<SignIn />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </React.StrictMode>
    </React.Suspense>
  );
}
