import { Text } from '@components/common/Text';
import React, { CSSProperties, ReactNode } from 'react';

export type ButtonColors = 'info' | 'primary' | 'danger';
export type ButtonTypes = 'flat' | 'raised';

export interface ButtonProps {
  /**
   * Marks the button as a action button and makes the text bold
   */
  asAction?: boolean;
  /**
   * An Optional React Node which is placed in the Icon section of the button.
   */
  icon?: JSX.Element;

  /**
   * Color of the button which is configured in ButtonColors
   */
  color?: ButtonColors;

  /**
   * Type of Button to use
   */
  type?: ButtonTypes;

  /**
   * Label or JSX.Element of the button
   */
  children?: string | JSX.Element;

  /**
   * Optional OnClick Callback
   */
  onClick?: () => void;

  /**
   * Marks the button as Disabled or not.
   */
  disabled?: boolean;

  /**
   * Used only for testing purposes.
   */
  testid?: string;

  /**
   * Additional custom styles if needed
   */
  styles?: CSSProperties;
}

/**
 * Primary UI component for user interaction
 */
export function Button({
  color = 'info',
  type: buttonType = 'flat',
  disabled = false,
  children = '',
  icon = undefined,
  asAction = false,
  onClick,
  styles,
  testid,
}: ButtonProps) {
  const onClickHandler = () => {
    if (!disabled) {
      onClick?.();
    }
  };
  return (
    <button
      style={styles}
      type="button"
      className={[
        'button',
        `button-${buttonType}`,
        `button-${color}`,
        icon !== undefined ? 'icon-button' : undefined,
        asAction ? 'action-button' : undefined,
      ]
        .filter((e) => e !== undefined)
        .join(' ')}
      disabled={disabled}
      onClick={onClickHandler}
    >
      {icon ?? (
        <span data-testid={testid} className="button-icon">
          {icon}
        </span>
      )}
      <Text.Paragraph1 className="button-label">{children}</Text.Paragraph1>
    </button>
  );
}
